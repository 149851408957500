// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-courses-template-js": () => import("./../src/Templates/courses-template.js" /* webpackChunkName: "component---src-templates-courses-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../src/Templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-courses-js": () => import("./../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-freshers-js": () => import("./../src/pages/freshers.js" /* webpackChunkName: "component---src-pages-freshers-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructor-js": () => import("./../src/pages/instructor.js" /* webpackChunkName: "component---src-pages-instructor-js" */),
  "component---src-pages-lifeat-educorp-js": () => import("./../src/pages/lifeatEducorp.js" /* webpackChunkName: "component---src-pages-lifeat-educorp-js" */),
  "component---src-pages-professionals-js": () => import("./../src/pages/professionals.js" /* webpackChunkName: "component---src-pages-professionals-js" */),
  "component---src-pages-student-js": () => import("./../src/pages/student.js" /* webpackChunkName: "component---src-pages-student-js" */),
  "component---src-pages-trainers-js": () => import("./../src/pages/trainers.js" /* webpackChunkName: "component---src-pages-trainers-js" */)
}

